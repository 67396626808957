import { defineStore } from 'pinia'
import newlistingsQuery from '../apollo/queries/pages/newlistings.gql'

export const useNewListingsStore = defineStore('newListings', {
  state: () => ({
    data: {}
  }),
  getters: {
    getNewlistings: (state) => {
      return state.data.newlisting ? state.data.newlisting.data.attributes : null
    }
  },
  actions: {
    async requestData() {
      if (Object.keys(this.data).length !== 0) return

      const { data } = await useAsyncQuery(newlistingsQuery) // eslint-disable-line no-undef

      if (data) this.data = data
    }
  }
})
